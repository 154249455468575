import React from "react";

export const ProductCard = ({
  cardTitle,
  products,
  activated,
  handleCheckbox,
  selectedState,
  setSelectedState,
  handleRadio,
  workshop2Allowed,
  workshop6Allowed,
}) => {
  const isChecked = selectedState !== null; // 打勾了

  if (products.length === 0) {
    return (
      <div className="card p-4 text-center">
        <h6>{cardTitle}</h6>
        <p className="text-success mt-4">
          <em>Purchased</em>
          <i className="fas fa-check ms-2"></i>
        </p>
      </div>
    );
  }

  if (products.every((p) => p.soldOut)) {
    return (
      <div className="card p-4 text-center">
        <h6>{cardTitle}</h6>
        <p className="text-danger mt-4">
          <em>Sold Out</em>
        </p>
      </div>
    );
  }

  return (
    <div className={isChecked ? "activate card p-4" : "deactivate card p-4"}>
      <div className="cardCheckbox">
        <input
          disabled={!activated}
          className={`form-check-input ${isChecked ? "d-block" : "d-none"}`}
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckbox}
        />
      </div>
      <div style={{ opacity: isChecked ? 1 : 0.9 }}>
        <h6>{cardTitle}</h6>

        <div className="d-flex flex-column gap-3 pt-2">
          {products.map(
            ({
              id,
              title,
              price,
              soldOut,
              buyerType,
              productName,
              productDescription,
            }) => {
              return (
                <div className="form-check" key={id}>
                  <input
                    disabled={
                      !activated || // false
                      soldOut || // false
                      (!workshop2Allowed && title === "workshop_2") || // false && true
                      (!workshop6Allowed && title === "workshop_6") // false && true
                    }
                    className="form-check-input"
                    name={cardTitle}
                    type="radio"
                    checked={selectedState == id}
                    id={id}
                    onChange={() => {
                      if (handleRadio) {
                        handleRadio();
                      }
                      setSelectedState(id);
                    }}
                  />
                  <label
                    className={`form-check-label ${
                      soldOut && "text-decoration-line-through"
                    }`}
                    htmlFor={id}
                  >
                    <div>
                      <small>
                        {title === "virtual" ? buyerType : productName}
                      </small>
                      <strong className="text-danger">{`- ${price}.00`}</strong>

                      <em>{soldOut ? " (SOLD OUT)" : ""}</em>
                    </div>

                    <div>
                      <small
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: productDescription,
                        }}
                      />
                    </div>
                  </label>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};
