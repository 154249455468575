import axios from "axios";
import React, { useState } from "react";
import { ProductCard } from "./ProductCard";

const ProductSelection = ({
  userId,
  token,
  url,
  physical,
  purchasedPhysical,
  galaDinner,
  purchasedGalaDinner,
  fullDayWorkshop,
  purchasedFullDayWorkshop,
  firstHalfDayWorkshop,
  purchasedFirstHalfDayWorkshop,
  secondHalfDayWorkshop,
  purchasedSecondHalfDayWorkshop,
  workshop2Allowed,
  workshop6Allowed,
  sst,
}) => {
  const [selected, setSelected] = useState(null);
  const [selectedGalaDinner, setSelectedGalaDinner] = useState(null);
  const [selectedFullDayWorkshop, setSelectedFullDayWorkshop] = useState(null);
  const [selectedFirstHalfDayWorkshop, setSelectedFirstHalfDayWorkshop] =
    useState(null);
  const [selectedSecondHalfDayWorkshop, setSelectedSecondHalfDayWorkshop] =
    useState(null);

  // physical is compulsory
  // if full day workshop, then first half day or second half day workshop is not allowed
  // if first half day workshop or second half day workshop, then full day workshop is not allowed

  // const disabled = purchasedPhysical
  //   ? selectedGalaDinner === null
  //   : selected === null;

  const disabled = purchasedPhysical
    ? selectedGalaDinner === null &&
      selectedFirstHalfDayWorkshop === null &&
      selectedSecondHalfDayWorkshop === null &&
      selectedFullDayWorkshop === null
    : selected === null;

  return (
    <div id="productSelection">
      <form action={url} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <input
          type="hidden"
          name="product_ids"
          value={[
            selected,
            selectedGalaDinner,
            selectedFullDayWorkshop,
            selectedFirstHalfDayWorkshop,
            selectedSecondHalfDayWorkshop,
          ]}
        />

        <ProductCard
          cardTitle={
            "Asia Oceanian Conference of Physical and Rehabilitation Medicine 2025"
          }
          products={physical}
          selectedState={selected}
          setSelectedState={setSelected}
          activated={!purchasedPhysical}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelected(null);
              // setSelectedGalaDinner(null);
            }
          }}
          handleRadio={() => {
            // setSelectedGalaDinner(null);
          }}
        />

        <h4 className="text-center text-navy mt-4">Select Gala Dinner</h4>
        {/* ------------ congress dinner CARD ------------ */}
        <ProductCard
          cardTitle={"Gala Dinner Ticket"}
          products={galaDinner}
          selectedState={selectedGalaDinner}
          setSelectedState={setSelectedGalaDinner}
          activated={!purchasedGalaDinner}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedGalaDinner(null);
            }
          }}
          handleRadio={() => {
            setSelectedGalaDinner(null);
          }}
        />

        <h4 className="text-center text-navy mt-4">Select Full Day Workshop</h4>
        {/* ------------ full day workshop CARD ------------ */}
        <ProductCard
          cardTitle={"Full Day Workshop Ticket (8:00am - 5:00pm)"}
          products={fullDayWorkshop}
          selectedState={selectedFullDayWorkshop}
          setSelectedState={setSelectedFullDayWorkshop}
          activated={!purchasedFullDayWorkshop}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedFullDayWorkshop(null);
            }
          }}
          handleRadio={() => {
            setSelectedFirstHalfDayWorkshop(null);
            setSelectedSecondHalfDayWorkshop(null);
          }}
        />

        <h4 className="text-center text-navy mt-4">Select Morning Workshop</h4>
        {/* ------------ first half day workshop CARD ------------ */}
        <ProductCard
          cardTitle={"Half Day Workshop Ticket (8:00am - 12:00pm)"}
          products={firstHalfDayWorkshop}
          selectedState={selectedFirstHalfDayWorkshop}
          setSelectedState={setSelectedFirstHalfDayWorkshop}
          activated={!purchasedFirstHalfDayWorkshop}
          workshop2Allowed={workshop2Allowed}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedFirstHalfDayWorkshop(null);
            }
          }}
          handleRadio={() => {
            setSelectedFullDayWorkshop(null);
          }}
        />

        <h4 className="text-center text-navy mt-4">
          Select Afternoon Workshop
        </h4>
        {/* ------------ second half day workshop CARD ------------ */}
        <ProductCard
          cardTitle={"Half Day Workshop Ticket (2:00pm - 5:00pm)"}
          products={secondHalfDayWorkshop}
          selectedState={selectedSecondHalfDayWorkshop}
          setSelectedState={setSelectedSecondHalfDayWorkshop}
          activated={!purchasedSecondHalfDayWorkshop}
          workshop6Allowed={workshop6Allowed}
          handleCheckbox={(e) => {
            if (!e.target.checked) {
              setSelectedSecondHalfDayWorkshop(null);
            }
          }}
          handleRadio={() => {
            setSelectedFullDayWorkshop(null);
          }}
        />

        {sst && (
          <strong className="d-block mx-auto">
            All purchases will incur 8% SST
          </strong>
        )}
        {/* for international delegate is compulsory to select one of the precongress */}
        <button
          type="submit"
          className="btn text-white btn-primary d-block mx-auto mt-4"
          disabled={disabled}
        >
          Purchase Pass
        </button>
      </form>
    </div>
  );
};

export default ProductSelection;
